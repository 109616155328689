html,
body,
#root {
  overflow: hidden;
  height: 100%;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
input,
select,
textarea,
button {
  font: 14px Helvetica, arial, freesans, clean, sans-serif;
  color: black;
  line-height: 1.4em;
}

strong {
  font-weight: 600;
}

code {
  padding: 0;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  margin: 0;
  font: 12px Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  background-color: #faf6f0;
  border-radius: 3px;
}

code:before,
code:after {
  letter-spacing: -0.2em;
  content: '\00a0';
}

:root {
  --color-tooltip-canvas: #280027;
  --color-tooltip-ink: #fff5ff;
  --icon-color: #770c5699;
}

@media (prefers-color-scheme: dark) {
  :root {
    color-scheme: dark;
    --icon-color: #585056;
  }

  code {
    background-color: #1e0814;
    color: #c5c4c2;
  }

  body,
  input,
  select,
  textarea,
  button {
    color: white;
  }
}
